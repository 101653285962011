:root {
  -primary: #17b0ec;
  --secondary: #1ebea5;
  --background: #07213a;
  --third: #dcdcdc;
  --pink: #ff4081;
  --danger: #f44336;

  --border-primary: #17b0ec;
  --border-secondary: #dcdcdc;

  --primary-text: #333;
  --secondary-text: #17b0ec;
  --third-text: #6c757d;

  --header-background: #103447;
  --header-background-secondary: #07213a;

  --body-background: #F5F5F5;

  --modal-header-background: #f0f2f5;

  --menu-primary: #332b38;

  --input-text: #F5F5F5;
  --input-text-placeholder: #a3a3a3;
  --input-background: #103447;
  --input-border: #141414;

  --button-primary: #1273aa;
  --button-primary-hover: rgb(37, 187, 247);
  --button-primary-text: #F5F5F5;
  --button-primary-text-hover: #fff;
  --button-primary-border: #17b0ec;
  --button-primary-border-hover: #17b0ec;

  --anchor-primary: #d5ccd1;
  --anchor-primary-hover: rgb(37, 187, 247);

  --column-container-height: calc(100vh - 15.2rem);

  --card-background: rgba(16, 52, 71, 0.05);
  --card-header-background: #f0f2f5;

  --project-item-title: #07213a;
  --project-icon: #8f8f8f;
  --project-icon-hover: #b8b7b7;
  --project-icon-project: #52c41a;
  --project-icon-backlog: #1273aa;
  --project-icon-repository: #f5222d;
  --project-icon-epic: #fa8c16;
  --project-icon-feature: #722ed1;
  --project-icon-sprint: #fadb14;

  --card-task-border: #fadb14;
  --card-task-input-background: #fff;
  --card-task-input-border: #F5F5F5;
  --card-task-input-text: #333;
  --card-task-input-text-placeholder: #a3a3a3;
  --card-task-input-background: #e0e0e0
}

.input-text-right input {
  text-align: right;
}

.input-text-right.ant-input-number input {
  padding-right: 8px;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.w-100 {
  width: 100%;
}

.m-b-8 {
  margin-bottom: 0.5rem;
}

.m-t-16 {
  margin-top: 1rem;
}

h2 {
  font-size: 1rem;
  font-weight: 700;
  color: var(--primary-text);
  text-align: left;
}