.ant-row {
  width: 100%;
}

.ant-form-item-label {
  margin-bottom: -6px;
}

.ant-picker,
.ant-input-number {
  width: 100%;
}

.ant-table-content {
  overflow-x: auto;
}

.is-mobile .ant-table {
  font-size: 12px;
}

.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item:hover,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu:hover,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-active,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-active,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-open,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-open,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-selected,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-selected {
  color: var(--menu-primary) !important;
}

.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-active::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-open::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-open::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-selected::after {
  border-bottom: 2px solid var(--border-primary);
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: var(--header-background-secondary);
}

.ant-pagination-item-active {
  border-color: var(--button-primary);
}

.ant-pagination-item-active a {
  color: var(--button-primary);
}